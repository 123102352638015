<template>
  <v-app-bar
    id="core-toolbar"
    app
    data-cy="toolbar"
  >
    <v-app-bar-nav-icon
      v-if="!isPartnerOverviewPage"
      data-cy="hamb-left"
      @click.stop="onClickBtn"
    />
    <v-toolbar-title
      data-cy="toolbar-title"
      class="pl-0 core-toolbar-title"
    >
      {{ title }}
    </v-toolbar-title>
    <v-spacer />
    <core-toolbar-pending-requests />
    <material-profile-setting-modal-window />
    <span
      class="ml-1"
      v-text="companyName"
    />
    <core-element-localization-selector />
    <v-icon
      data-cy="logout"
      @click.stop="logout"
    >
      {{ logoutIcon }}
    </v-icon>
    <v-app-bar-nav-icon
      v-if="productDrawerVisible"
      data-cy="product-hamburger"
      @click.stop="toggleProductsDrawer"
    />
  </v-app-bar>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex'
import { LOGOUT_ICON, ROLE_ADMIN, ROLE_NAME } from '../../constants/app'
import { ROUTE_OVERVIEW, ROUTE_PARTNER_VIEW, ROUTE_PRODUCT_DETAILS } from '../../constants/routeNames'

export default {
  data: () => ({
    logoutIcon: LOGOUT_ICON,
    adminRole: ROLE_ADMIN
  }),
  computed: {
    ...mapGetters('client', { clientGetter: 'clientById' }),
    title () {
      let name = this.$options.filters.camelize(this.$route.name)
      if (this.$route.name === ROUTE_OVERVIEW && this.isPartnerOverviewPage) {
        name = ROUTE_PARTNER_VIEW
      }
      return this.$i18n.t(`drawer.${this.camelize(name)}`)
    },
    companyName () {
      const companyName = this.clientGetter(this.$route.params.clientId).companyName
      return companyName ? ` - ${companyName}` : ''
    },
    isPartnerLoggedIn () {
      return localStorage.getItem(ROLE_NAME) === ROLE_ADMIN
    },
    isPartnerOverviewPage () {
      return (
        this.isPartnerLoggedIn &&
        this.$route.params.clientId === 'my'
      )
    },
    productDrawerVisible () {
      return this.$route.name && this.$route.name.match(ROUTE_PRODUCT_DETAILS)
    }
  },
  methods: {
    ...mapMutations('layout', ['setDrawer', 'setProductsDrawer']),
    ...mapActions('login', ['logout']),
    onClickBtn () {
      this.setDrawer(!this.$store.state.layout.drawer)
    },
    toggleProductsDrawer () {
      this.setProductsDrawer(!this.$store.state.layout.productsDrawer)
    },
    camelize (string) {
      return this.$options.filters.camelize(string)
    }
  }
}
</script>
